import { Helmet } from "react-helmet-async";
import useFilterByIdParam from "../hooks/useFilterByIdParam";
import useScreenSize from "../hooks/useScreenSize";
import ReactGA from 'react-ga4';
import hostessesArray from "../constants/HostessesArray";
import { Navigate } from "react-router-dom";
import { Card, Image } from "react-bootstrap";
import telroseFrance from '../img/telrose_France.webp';

function Profil({ sva }) {

  const hostesseProfil = useFilterByIdParam(hostessesArray);

  const { width } = useScreenSize();

  function handleClick({ category, action }) {
    ReactGA.event({
      category: category,
      action: action
    });
  }

  if (hostesseProfil) {
    return (
      <>
        <Helmet>
          {hostesseProfil.map(hostesse => (
            <>
              <title key={hostesse.id}>{hostesse.title}</title>
              <meta name="description"
                content={hostesse.metaDescription} />
              <link rel="canonical" href={`https://telrose-duo.fr/profil-hotesse/${hostesse.id}/${hostesse.name}`} />
            </>
          ))}
        </Helmet>
        <h1 className="neon text-center mb-4 d-none">Les hôtesses</h1>
        <section className="section-profil rounded-5 px-4 pt-4 pb-1 mb-5 bg-black">
          {hostesseProfil.map(hostesse => (
            <Card key={hostesse.id} className="text-light fade-cards rounded-4 bg-black">
              <Card.Img className="rounded-top-4 rounded-bottom-0" src={`/img/photos/${hostesse.photo}.webp`} />
              <Card.Body className="px-2 pb-0">
                <Card.Title as={"h1"} className="neon text-center">{hostesse.name}</Card.Title>
                <Card.Text as={"div"}>
                  {hostesse.description}
                </Card.Text>
                {width < 576 ?
                  <div className="text-center mb-3">
                    <a href={`tel:${sva.tel}`} onClick={() => handleClick({ category: `sva ${sva.name}- Page de profil`, action: "Click to Call" })}>
                      <Image className="mx-auto d-block mb-4" src={sva.img} width={sva.width} rounded />
                    </a>
                    <a className="mx-auto link-home p-2" href={`tel:${sva.tel}`} onClick={() => handleClick({ category: `Bouton Appeler ${sva.name}- Page de Profil`, action: "Click to Call" })}>Appeler</a>
                  </div>
                  :
                  <Image className="mx-auto d-block mb-3" src={sva.img} width={sva.width} rounded />
                }
              </Card.Body>
            </Card>
          ))}
        </section>
      </>
    )
  }
  return (
    <Navigate to="/404" />
  )
}

export default Profil;