import { useEffect, useState } from 'react';
import useScreenSize from '../hooks/useScreenSize';
import ReactGA from 'react-ga4';
import { NavLink } from 'react-router-dom';
import { Navbar, Container, Nav, Offcanvas, Dropdown, Image } from 'react-bootstrap';
import telroseFrance from '../img/telrose_France.webp';
import telrosePrivé from '../img/telrose_privé.webp';
import telroseAudiotel from '../img/forfait_telrose.webp';
import telroseBelgique from '../img/telrose_Belgique.webp';
import telroseLuxembourg from '../img/telrose_Luxembourg.webp';
import telroseSuisse from '../img/telrose_Suisse.webp';

function Header({ pathname, sva, setSva }) {

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => {
    setShow(true);
  }

  function handleClick({ category, action }) {
    ReactGA.event({
      category: category,
      action: action
    });
  }

  const { width } = useScreenSize();

  useEffect(() => {

    pathname === "/duo-prive" ? setSva({ ...sva, img: telrosePrivé, name: "Duo-privé", tel: "0178967730", width: width < 528 && width > 400 ? 205 : width < 400 ? 180 : 260 })
      : pathname === "/audiotel" ? setSva({ ...sva, img: telroseAudiotel, name: "Audiotel", tel: "0170979055", width: width < 528 && width > 400 ? 205 : width < 400 ? 180 : 260 })
        : sva.country === "BE" ? setSva({ ...sva, img: telroseBelgique, name: "Standard Belgique", tel: "090340840", width: 144 })
          : sva.country === "LU" ? setSva({ ...sva, img: telroseLuxembourg, name: "Standard Luxembourg", tel: "90528006", width: 144 })
            : sva.country === "CH" ? setSva({ ...sva, img: telroseSuisse, name: "Standard Suisse", tel: "0901494494", width: 144 })
              : setSva({ ...sva, img: telroseFrance, name: "Standard France", tel: "0895900400", width: 260 })
    window.scrollTo(0, 0);
  }, [pathname])

  return (
    <header className='sticky-top'>

      <Navbar expand="xl" data-bs-theme="dark" className="bg-black p-2 mb-4">
        <Container className='px-0 px-sm-2'>
          <div className='d-flex'>
            <Navbar.Toggle className='border-0 shadow-none ps-0' onClick={handleShow} controls={`offcanvasNavbar-expand-md`} />
            <NavLink to={"/"} className="text-decoration-none align-self-center">
              <Navbar.Brand as={"span"} className={width < 528 ? "me-2 neon" : "me-5 neon"}>télrose-duo.fr</Navbar.Brand>
            </NavLink>
          </div>
          {width < 1200 &&
            <div>
              {width < 576 ?
                <a href={`tel:${sva.tel}`} onClick={() => handleClick({ category: `sva ${sva.name} - Navbar`, action: "Click to Call" })}>
                  <Image id="sva-mobile" src={sva.img} width={sva.country === "FR" && width < 528 && width > 400 ? 205 : sva.country === "FR" && width < 400 ? 180 : sva.width} rounded alt='Sva' />
                </a>
                :
                <Image id="sva-mobile" src={sva.img} width={sva.country === "FR" && width < 528 && width > 400 ? 205 : sva.country === "FR" && width < 400 ? 180 : sva.width} rounded alt='Sva' />
              }
            </div>
          }
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-sm`}
            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
            show={show}
            onHide={handleClose}
            placement="start"
            data-bs-theme="dark"
            className="bg-black"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`} className='neon'>
                télrose-duo.fr
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className='flex-grow-1'>
                <NavLink onClick={() => { handleClose(); setSva({ country: sva.country, img: sva.img, width: sva.width, tel: sva.tel, name: sva.name }) }} className='me-4 p-2 nav-link' to="/">Accueil</NavLink>
                <NavLink onClick={() => { handleClose(); setSva({ country: sva.country, img: sva.img, width: sva.width, tel: sva.tel, name: sva.name }) }} className='me-4 p-2 nav-link' to="/hotesses" caseSensitive end>Hôtesses</NavLink>
                <NavLink onClick={() => { handleClose(); setSva({ country: sva.country, img: telrosePrivé, width: 260, tel: "0178967730", name: "Duo-privé" }) }} className='me-4 p-2 nav-link' to="/duo-prive" caseSensitive end>Duo privé</NavLink>
                <NavLink onClick={() => { handleClose(); setSva({ country: sva.country, img: telroseAudiotel, width: 260, tel: "0170979055", name: "Audiotel" }) }} className='me-4 p-2 nav-link' to="/audiotel" caseSensitive end>Audiotel</NavLink>
              </Nav>
              <div className='mt-3 mt-xl-0'>
                {pathname === "/duo-prive" ? null
                  : pathname === "/audiotel" ? null
                    :
                    <Dropdown className='d-inline'>
                      <Dropdown.Toggle variant='black' className='border-0'>
                        {sva.country}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setSva({
                          country: "FR", img: telroseFrance, width: 260, tel: "0895900400", name: "Standard France",
                        })}>France</Dropdown.Item>
                        <Dropdown.Item onClick={() => setSva({
                          country: "BE", img: telroseBelgique, width: 144, tel: "090340840", name: "Standard Belgique"
                        })}>Belgique</Dropdown.Item>
                        <Dropdown.Item onClick={() => setSva({
                          country: "LU", img: telroseLuxembourg, width: 144, tel: "90528006", name: "Standard Luxembourg"
                        })}>Luxembourg</Dropdown.Item>
                        <Dropdown.Item onClick={() => setSva({
                          country: "CH", img: telroseSuisse, width: 144, tel: "0901494494", name: "Standard Suisse"
                        })}>Suisse</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                }
                {width < 576 ?
                  <a href={`tel:${sva.tel}`} onClick={() => handleClick({ category: `sva ${sva.name} - Navbar`, action: "Click to Call" })}>
                    <Image id='sva-navbar' src={sva.img} width={sva.country === "FR" && width < 528 ? 240 : sva.width} rounded alt='Sva' />
                  </a>
                  :
                  <Image id='sva-navbar' src={sva.img} width={sva.country === "FR" && width < 528 ? 240 : sva.width} rounded alt='Sva' />
                }
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;