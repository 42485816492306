import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';

function Audiotel() {

  function handleClick({ category, action }) {
    ReactGA.event({
      category: category,
      action: action
    });
  }

  return (
    <>
      <Helmet>
        <title>Forfaits Audiotel : découvrez nos offres de téléphone rose au 0170979055</title>
        <meta name="description"
          content="Explorez nos forfaits de téléphone rose et plongez dans un monde d'intimité et de plaisir vocal au 0170979055." />
        <link rel="canonical" href="https://telrose-duo.fr/audiotel" />
      </Helmet>
      <h1 className="neon text-center mb-5">Forfaits Audiotel</h1>
      <section className="section bg-black p-4 mb-5 rounded-5">
        <article>
          <h2 className="neon text-center pb-3">Inscription et paiements</h2>
          <p><strong>- Paiement CB / Sécurisé / Discret</strong>.</p>
          <p>- Composez le <strong><a className="external-link" href="tel:01 70 97 90 55" onClick={() => handleClick({ category: "Numero Audiotel - Page Audiotel", action: "Click to Call" })}>01 70 97 90 55</a>
          </strong> et dialoguez en direct.
            <br />
            (Sans perte de temps, le standard détecte votre compte à l'appel)
          </p>
          <p>- Créez votre compte client si vous ne l'avez pas déjà fait en cliquant sur le lien suivant : <a className="external-link" href="https://app.dialotel.io/register/72-sexy-love" target="_blank" rel="noopener noreferrer">Inscription rapide</a>, ou bien connectez-vous directement à votre compte :  <a className="external-link" href="https://app.dialotel.io/login/72-sexy-love" target="_blank" rel="noopener noreferrer">Accéder à mon compte</a>.</p>
          <p>- Vous devez cliquer sur le forfait de votre choix (voir les forfaits ci-dessous) et effectuer un paiement CB sécurisé <strong>3D SECURE</strong>.</p>
          <p>- Une fois le paiement effectué appelez le <strong>
            <a className="external-link" href="tel:01 70 97 90 55" onClick={() => handleClick({ category: "Numero Audiotel - Page Audiotel", action: "Click to Call" })}>01 70 97 90 55</a>
          </strong>.
            <br />
            (Si c'est la première fois vous devrez entrer votre numéro client qui est noté en bleu sur votre compte client.) </p>
          <p>- Choisissez l'hôtesse de votre choix. Les forfaits sont utilisables en plusieurs fois avec des hôtesses différentes.</p>
          <p>- Toutes les hôtesses de notre réseau de téléphone rose sont disponibles avec les forfaits. </p>
          <p>- Les demandes d'informations relatives à vos paiements ou réclamations se font sur <a className="external-link" href="https://customer.centralpay.net/home/d7f84f70-2af2-42e0-9c94-4beaef852bc1" target="_blank" rel="noopener noreferrer">Informations Paiements CB</a>.</p>
        </article>
        <hr />
        <article>
          <h2 className="neon text-center pb-3">Les forfaits</h2>
          <iframe id="iframe-packages-audiotel" src="https://app.dialotel.io/forfaitaudiotel/iframe_forfaitsaudiotel/1/72" width={"100%"} title="packages-audiotel"></iframe>
        </article>
      </section>
    </>
  )
}

export default Audiotel;