import { NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";
import ReactGA from 'react-ga4';

function Footer() {

  function handleClick({ category, action }) {
    ReactGA.event({
      category: category,
      action: action
    });
  }

  return (
    <footer className='text-light text-center p-3'>
      <Container>
        <div>
          <NavLink className="text-decoration-none link-footer px-2 text-white-50" to={"/"} caseSensitive end>Accueil</NavLink>
          <NavLink className="text-decoration-none link-footer px-2 text-white-50" to={"/duo-prive"} caseSensitive end>Duo privé</NavLink>
          <NavLink className="text-decoration-none link-footer px-2 text-white-50" to={"/audiotel"} caseSensitive end>Audiotel</NavLink>
        </div>
        <div>
          <span>Site partenaire : <a className="external-link" href="https://allodial.fr/" target="_blanck"
            onClick={() => handleClick({ category: "Link to allodial.fr - Footer", action: "Click to link" })}>Allodial.fr</a></span>
        </div>
        <div>
          <NavLink className="text-decoration-underline link-footer pe-1 text-white-50" to={"/mentions-legales"} caseSensitive end>Mentions légales</NavLink>
          <NavLink className="text-decoration-underline link-footer px-1 text-white-50" to={"/cgv"} caseSensitive end>CGV</NavLink>
          <a className="text-decoration-underline link-footer ps-1 text-white-50" href="https://customer.centralpay.net/home/d7f84f70-2af2-42e0-9c94-4beaef852bc1" target='_blank' rel="noopener noreferrer">Informations Paiements</a>
        </div>
        <small>Copyright &copy; 2024 télrose-duo.fr. Tous droits réservés.</small>
      </Container>
    </footer>
  )
}

export default Footer;