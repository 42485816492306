import { useState } from "react";
import { Pagination } from "react-bootstrap";

function PaginationBasic({ setCurrentPage, pagesNumber }) {

  const [active, setActive] = useState(1);

  let items = [];
  for (let number = 1; number <= pagesNumber; number++) {
    items.push(
      <Pagination.Item linkClassName="button-pagination" className="px-1" as={"button"} key={number} onClick={() => { setActive(number); setCurrentPage(number); }} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }
  const paginationBasic = (
    <Pagination className="justify-content-center m-0">{items}</Pagination>
  )
  return (
    <>
      {paginationBasic}
    </>
  )
}

export default PaginationBasic;