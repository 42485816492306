import { Helmet } from "react-helmet-async";
import hostesses from "../constants/HostessesArray";
import useScreenSize from "../hooks/useScreenSize";
import { useState } from "react";
import { Row, Col, Card, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Pagination from "../components/Pagination";

function HostessesList() {

  const [currentPage, setCurrentPage] = useState(1);
  const { width } = useScreenSize();

  const filteredHostessesListDesktop = hostesses.filter(hostesse => (
    hostesse.id <= currentPage * 6 && hostesse.id > currentPage * 6 - 6));

  const filteredHostessesListMobile = hostesses.filter(hostesse => (
    hostesse.id <= currentPage * 8 && hostesse.id > currentPage * 8 - 8));



  return (
    <>
      <Helmet>
        <title>Hôtesses sensuelles : dialoguez en toute intimité par téléphone au 0895900400</title>
        <meta name="description"
          content="Plongez dans un univers de séduction et d'intimité vocale avec nos hôtesses de téléphone rose au 0895900400." />
        <link rel="canonical" href="https://telrose-duo.fr/hotesses" />
      </Helmet>
      <h1 className="neon text-center mb-5">Les hôtesses</h1>
      <section className="section bg-black p-4 mb-5 rounded-5">
        {width >= 576 ?
          <>
            <Row as={'ul'} className="mb-2 hostesses-grid">
              {filteredHostessesListDesktop.map(hostesse => (
                <Col as={'li'} className="position-relative text-center" key={hostesse.id} xs={6} sm={6} lg={4}>
                  <NavLink className="text-decoration-none fade-cards" to={`/profil-hotesse/${hostesse.id}/${hostesse.name}`} caseSensitive end>
                    <Card bsPrefix="hostesses-list-card" className="rounded-4 mb-3">
                      <Image className="photo-hostesse rounded-4" src={`/img/photos/${hostesse.photo}.webp`} fluid={"true"} alt="Hôtesse en lingerie" />
                      <h4 className="neon position-absolute pb-2 bottom-0 start-50 translate-middle-x">{hostesse.name}</h4>
                    </Card>
                  </NavLink>
                </Col>
              ))}
            </Row>
            <Pagination setCurrentPage={setCurrentPage} pagesNumber={4} />
          </>
          :
          <>
            <Row as={'ul'} className="mb-2 hostesses-grid">
              {filteredHostessesListMobile.map(hostesse => (
                <Col as={'li'} className="position-relative text-center" key={hostesse.id} xs={6} sm={6} lg={4}>
                  <NavLink className="text-decoration-none fade-cards" to={`/profil-hotesse/${hostesse.id}/${hostesse.name}`} caseSensitive end>
                    <Card bsPrefix="hostesses-list-card" className="rounded-4 mb-3">
                      <Image className="photo-hostesse rounded-4" src={`/img/photos/${hostesse.photo}.webp`} fluid={"true"} alt="Hôtesse en lingerie" />
                      <h6 className="neon position-absolute pb-3 bottom-0 start-50 translate-middle-x">{hostesse.name}</h6>
                    </Card>
                  </NavLink>
                </Col>
              ))}
            </Row>
            <Pagination setCurrentPage={setCurrentPage} pagesNumber={3} />
          </>
        }
      </section>
    </>
  )
}

export default HostessesList;