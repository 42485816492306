import { Helmet } from "react-helmet-async";

function PrivateDuo() {
  return (
    <>
      <Helmet>
        <title>Duo privé pour moments chauds au téléphone : 0178967730</title>
        <meta name="description"
          content="Plongez dans des échanges en duo avec nos hôtesses expertes, prêtes à réaliser vos désirs les plus secrets au 0178967730." />
        <link rel="canonical" href="https://telrose-duo.fr/duo-prive" />
      </Helmet>
      <h1 className="text-center mb-5 neon">Duo privé</h1>
      <section className="section bg-black p-4 mb-5 rounded-5">
        <article className="text-center mb-4">
          <h2 className="neon pb-3">Bienvenue dans notre espace confidentiel</h2>
          <p>Entrez dans un univers de plaisirs sans limite, où chaque mot susurre l'érotisme et chaque silence brûle de désir. Avec nos hôtesses de téléphone rose, chaque conversation est un voyage sensuel, une exploration de vos fantasmes les plus secrets.</p>
          <p>Laissez-vous guider par leur voix douce et envoûtante, laissez-vous emporter par le frisson de l'inconnu alors que vous partagez vos désirs les plus profonds.</p>
          <p>Dans cet espace privé, la passion est notre seule règle, et votre satisfaction notre seule ambition.</p>
        </article>
        <hr />
        <article>
          <h2 className="neon text-center pb-3">Inscription et paiements</h2>
          <h5>Le paiement du téléphone rose par CB est un moyen sûr et fiable, pas de surprise. (Un paiement CB totalement sécurisé <strong>3D SECURE</strong>).  </h5>
          <h6 className="pb-4">C’est vous qui choisissez le temps que vous souhaitez passer en ligne.</h6>
          <p>- Créez votre compte client si vous ne l'avez pas déjà fait en cliquant sur le lien suivant : <a className="external-link" href="https://app.dialotel.io/register/72-sexy-love" target="_blank" rel="noopener noreferrer">Inscription rapide</a>, ou bien connectez-vous directement à votre compte :  <a className="external-link" href="https://app.dialotel.io/login/72-sexy-love" target="_blank" rel="noopener noreferrer">Accéder à mon compte</a>.</p>
          <p>- Le téléphone rose par CB privé est accessible 24h/24h - 7j/7j, vous trouverez la liste des hôtesses plus bas sur cette page.</p>
          <p>- Pour lancer une consultation <strong>sans forfait</strong>, choisissez une hôtesse disponible en cliquant sur son profil. Ensuite cliquez sur <strong>lancer l'appel à la minute</strong>.</p>
          <p>- Pour lancer une consultation <strong>avec forfait</strong>, il faut d'abord choisir parmis les différents <strong>forfaits privés</strong> (voir les forfaits ci-dessous), puis choisissez une hôtesse disponible en cliquant sur son profil. Ensuite cliquez sur <strong>lancer l'appel au forfait</strong>.</p>
          <p>- La consultation au forfait est disponible 24h/24 avec toutes nos hôtesses de téléphone rose.</p>
          <p>- Les forfaits sont utilisables en plusieurs fois avec des hôtesses différentes.</p>
          <p>- Les demandes d'informations relatives à vos paiements ou réclamations se font sur <a className="external-link" href="https://customer.centralpay.net/home/d7f84f70-2af2-42e0-9c94-4beaef852bc1" target="_blank" rel="noopener noreferrer">Informations Paiements CB</a>.</p>
        </article>
        <hr />
        <article>
          <h2 className="neon text-center pb-3">Les forfaits</h2>
          <iframe id="iframe-packages-duo" src="https://app.dialotel.io/forfaitprive/iframe_forfaits/72/1" width={"100%"} title="private-packages"></iframe>
        </article>
        <hr />
        <article>
          <h2 className="neon text-center pb-3">Les hôtesses</h2>
          <iframe id="iframe-hostesses" src="https://moduleweb.dialotel.io/modulweb/token/6422fbfb98a59" width={"100%"} title="hostesses-list"></iframe>
        </article>
      </section>
    </>
  )
}

export default PrivateDuo;