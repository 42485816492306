import { Helmet } from "react-helmet-async";
import useScreenSize from "../hooks/useScreenSize";
import ReactGA from 'react-ga4';
import { Row, Col, Image } from "react-bootstrap";
import Carousel from '../components/CarouselHostesses';
import { NavLink } from "react-router-dom";
import telroseFrance from '../img/telrose_France.webp';
import telrosePrivé from '../img/telrose_privé.webp';
import telroseAudiotel from '../img/forfait_telrose.webp';
import screenshotAllodial from '../img/screenshot-allodial.webp';

function Home({ sva }) {

  const { width } = useScreenSize();

  function handleClick({ category, action }) {
    ReactGA.event({
      category: category,
      action: action
    });
  }

  return (
    <>
      <Helmet>
        <title>Bienvenue sur télrose-duo.fr, appel direct au 0895900400</title>
        <meta name="description"
          content="Explorez votre sensualité avec notre service de téléphone rose. Appels directs, duos privés, et forfaits audiotel disponibles pour des moments intenses et discrets." />
        <link rel="canonical" href="https://telrose-duo.fr" />
      </Helmet>
      <h1 className="text-center mb-5" >Bienvenue sur <span className="neon-home ">télrose-duo.fr</span></h1>
      <section className="section bg-black p-4 rounded-5 mb-5">
        <Row>
          <Col lg={4} className="mb-4 mb-md-3">
            <article className="px-2">
              <h2 className="neon pb-3 text-center">Appel Direct</h2>
              {width < 576 ?
                <a href={`tel:${sva.tel}`} onClick={() => handleClick({ category: `sva ${sva.name}- Page d'accueil`, action: "Click to Call" })}>
                  <Image className="mx-auto d-block mb-3" src={sva.img} width={width < 528 ? 220 : 260} rounded alt="sva" />
                </a>
                :
                <Image className="mx-auto d-block mb-3" src={sva.img} width={width < 528 ? 220 : 260} rounded alt="sva" />
              }
              <p className="mb-4 text-center">Plongez dans un monde de plaisir sans limite avec nos appels directs de téléphone rose, facturés discrètement sur votre abonnement téléphonique. Pas de carte de crédit nécessaire, pas de transaction compliquée.</p>
              <div className="text-center">
                {width < 576 ?
                  <a className="link-home p-2" href={`tel:${sva.tel}`} onClick={() => handleClick({ category: `Bouton Appeler ${sva.name} - Page d'accueil`, action: "Click to Call" })}>Appeler</a>
                  :
                  null
                }
              </div>
            </article>
          </Col>
          {width < 768 && <hr />}
          <Col lg={4} className="mb-4 mb-md-3">
            <article className="px-2">
              <h2 className="neon pb-3 text-center">Duo privé</h2>
              {width < 576 ?
                <a href="tel:0178967730" onClick={() => handleClick({ category: "sva Duo-privé - Page d'accueil", action: "Click to Call" })}>
                  <Image className="mx-auto d-block mb-3" src={telrosePrivé} width={width < 528 ? 220 : 260} rounded alt="sva" />
                </a>
                :
                <Image className="mx-auto d-block mb-3" src={telrosePrivé} width={width < 528 ? 220 : 260} rounded alt="sva" />
              }
              <p className="mb-4 text-center">Dans notre monde de conversation privée, laissez-vous emporter par un océan de désirs inexprimés. Nos hôtesses sont là, prêtes à vous emmener dans un voyage sensuel où vos fantasmes les plus profonds deviennent réalité.</p>
              <div className="text-center">
                <NavLink className="link-home p-2" to={"/duo-prive"} caseSensitive end>Duo privé</NavLink>
              </div>
            </article>
          </Col>
          {width < 768 && <hr />}
          <Col lg={4} className="mb-2">
            <article className="px-2">
              <h2 className="neon pb-3 text-center">Forfaits Audiotel</h2>
              {width < 576 ?
                <a href="tel:0170979055" onClick={() => handleClick({ category: "sva Audiotel - Page d'accueil", action: "Click to Call" })}>
                  <Image className="mx-auto d-block mb-3" src={telroseAudiotel} width={width < 528 ? 220 : 260} rounded alt="sva" />
                </a>
                : <Image className="mx-auto d-block mb-3" src={telroseAudiotel} width={width < 528 ? 220 : 260} rounded alt="sva" />
              }
              <p className="mb-4 text-center">Découvrez nos forfaits de téléphone rose et plongez dans un univers de plaisir sans limites. Profitez de tarifs avantageux et de moments inoubliables avec nos hôtesses expérimentées, prêtes à vous guider vers des sommets de satisfaction.</p>
              <div className="text-center">
                <NavLink className="link-home p-2" to={"/audiotel"} caseSensitive end>Audiotel</NavLink>
              </div>
            </article>
          </Col>
        </Row>
        <hr />
        <article className="pb-3 text-center">
          <h2 className="neon pb-3">Hôtesses</h2>
          <Carousel />
          <NavLink className="link-home p-2" to={"/hotesses"} caseSensitive end>Hôtesses</NavLink>
        </article>
        <hr />
        <article className="text-center pb-3">
          <h2 className="pb-3">
            Notre site partenaire <span className="neon">Allodial.fr</span>
          </h2>
          <a href="https://allodial.fr/" target="_blanck"
            onClick={() => handleClick({ category: "Capture d'écran : allodial.fr' - Page Accueil", action: "Click to Link" })}
          >
            <Image className="rounded-3" src={screenshotAllodial} fluid alt="Capture d'écran du site partenaire : allodial.fr" />
          </a>
          <h3 className="fs-4 pt-4">Découvrez Notre Service de Téléphone Rose à Prix Mini</h3>
          <h4 className="fs-5 pb-3">Un plaisir sans limites, directement par appel</h4>
          <p className="mb-4">Envie de pimenter vos soirées avec des conversations torrides et sensuelles ? Bienvenue sur notre site de téléphone rose pas cher, où vos désirs deviennent réalité en un simple coup de fil. Nos hôtesses sexy et envoûtantes sont prêtes à vous offrir une expérience inoubliable, adaptée à vos fantasmes les plus secrets.</p>
          <div>
            <a className="link-home p-2" href="https://allodial.fr/" target="_blanck"
              onClick={() => handleClick({ category: "Bouton 'Accéder au site' - Page Accueil", action: "Click to Link" })}>Accéder au site</a>
          </div>
        </article>
      </section>
    </>
  )
}

export default Home;