import { Helmet } from "react-helmet-async";

function LegalNotice() {
  return (
    <>
      <Helmet>
        <title>Mentions légales et politique de confidentialité : télrose-duo.fr</title>
        <meta name="description"
          content="Retrouvez toutes les informations concernant les mentions légales et la politique de confidentialité." />
        <link rel="canonical" href="https://telrose-duo.fr/mentions-legales" />
      </Helmet>
      <section className="section bg-black p-4 mb-5 rounded-5">
        <h1 className="text-center mb-5">Mentions légales et politique de confidentialité</h1>
        <p>L'entreprise individuelle Bastien FOUCAT, soucieuse des droits des individus, notamment au regard des traitements automatisés et dans une volonté de transparence avec ses clients, a mis en place une politique reprenant l’ensemble de ces traitements, des finalités poursuivies par ces derniers ainsi que des moyens d’actions à la disposition des individus afin qu’ils puissent au mieux exercer leurs droits.
          Pour toute information complémentaire sur la protection des données personnelles, nous vous invitons à consulter le site : <a className="external-link" href="https://www.cnil.fr/" target="_blank" rel="noopener noreferrer">https://www.cnil.fr/</a>.
          <br />
          La poursuite de la navigation sur ce site vaut acceptation sans réserve des dispositions et conditions d'utilisation qui suivent.
          La version actuellement en ligne de ces conditions d'utilisation est la seule opposable pendant toute la durée d'utilisation du site et jusqu'à ce qu'une nouvelle version la remplace.</p>
        <article className="mt-5">
          <h5 className="mb-3">Mentions légales</h5>
          <p>- Site (ci-après « le site ») : <a className="external-link" href="https://telrose-duo.fr" target="_blank" rel="noopener noreferrer">https://telrose-duo.fr</a></p>
          <p>- Éditeur (ci-après « l'éditeur »)
            <br />
            L'entreprise individuelle Bastien Foucat
            <br />
            Située : 14 RUE HÉBERT, 92140 CLAMART
            <br />
            Immatriculée au RCS de 497 931 535
            <br />
            Adresse mail : <a className="external-link" href="mailto:sexotel@hotmail.com" target="_blank" rel="noopener noreferrer">sexotel@hotmail.com</a>
          </p>
          <p>- Hébergeur (ci-après « l'hébergeur ») :
            <br />
            <a className="external-link" href="https://telrose-duo.fr" target="_blank" rel="noopener noreferrer">https://telrose-duo.fr</a> est hébergé par OVH, dont le siège social est situé 2 rue Kellermann  59100 Roubaix - France.</p>
        </article>
        <article className="mt-5">
          <h5 className="mb-3">Art. 6, III, 1 de la loi pour la confiance dans l'économie numérique (LCEN)</h5>
          <p>En vous connectant à ce site édité par la société, vous accédez à un contenu protégé par la loi, notamment pour les dispositions du Code de la propriété intellectuelle. L'éditeur n'autorise qu'un usage strictement personnel des données, informations ou contenu auquel vous accédez, limité à un enregistrement temporaire sur votre ordinateur aux fins d'affichage sur un seul écran ainsi que la reproduction, en un unique exemplaire, pour copie de sauvegarde ou impression papier. Toute autre utilisation est soumise à notre autorisation expresse préalable.</p>
          <p>En poursuivant votre visite de notre site, vous acceptez de respecter les restrictions ci-dessus. Droits de reproduction et de diffusion réservés.</p>
        </article>
      </section>
    </>
  )
}

export default LegalNotice;