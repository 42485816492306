import './App.css';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/Header';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga4';
import Main from './components/Main';
import Footer from './components/Footer';

function App() {

  const { pathname } = useLocation();

  const [sva, setSva] = useState({
    country: "FR",
    img: "",
    name: "",
    width: "",
    tel: ""
  });

  const TRACKER_ID = process.env.REACT_APP_GA_TRACKER_ID;

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: TRACKER_ID,
        gtagOptions: {
          send_page_view: false
        },
      }
    ]);
    ReactGA.send({ hitType: "pageview", page: pathname, title: pathname === "/" ? "Accueil" : pathname.charAt(1).toUpperCase() + pathname.substring(2) });
  }, [pathname])

  return (
    <HelmetProvider>
      <Header pathname={pathname} sva={sva} setSva={setSva} />
      <Main sva={sva} />
      <Footer />
    </HelmetProvider>
  );
}

export default App;
