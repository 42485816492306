import useScreenSize from "../hooks/useScreenSize";
import hostessesArray from "../constants/HostessesArray";
import { NavLink } from "react-router-dom";
import { Carousel, Row, Col } from "react-bootstrap";
import { Image } from "react-bootstrap";

function CarouselHostesses() {

  const { width } = useScreenSize();

  if (width >= 470) {
    return (
      <>
        <Carousel className="bg-black mb-4" indicators={false} controls={false}>
          <Carousel.Item>
            <Row className="carousel-grid">
              {hostessesArray.filter(hostesse => hostesse.id <= 3).map(hostesse => (
                <Col key={hostesse.id} className="col-carousel rounded-4 position-relative">
                  <NavLink to={"/hotesses"} caseSensitive end>
                    <Image className="rounded-4 photo-hostesse" src={`/img/photos/${hostesse.photo}.webp`} fluid rounded alt="Hôtesse en lingerie"/>
                    {width > 767 ?
                      <h4 className="neon position-absolute bottom-0 start-50 translate-middle-x">{hostesse.name}</h4>
                      :
                      <h6 className="neon position-absolute bottom-0 start-50 translate-middle-x">{hostesse.name}</h6>
                    }
                  </NavLink>
                </Col>
              ))}
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row className="carousel-grid">
              {hostessesArray.filter(hostesse => hostesse.id > 3 && hostesse.id <= 6).map(hostesse => (
                <Col key={hostesse.id} className="col-carousel rounded-4 position-relative">
                  <NavLink to={"/hotesses"} caseSensitive end>
                    <Image className="rounded-4 photo-hostesse" src={`/img/photos/${hostesse.photo}.webp`} fluid rounded alt="Hôtesse en lingerie"/>
                    {width > 767 ?
                      <h4 className="neon position-absolute bottom-0 start-50 translate-middle-x">{hostesse.name}</h4>
                      :
                      <h6 className="neon position-absolute bottom-0 start-50 translate-middle-x">{hostesse.name}</h6>
                    }
                  </NavLink>
                </Col>
              ))}
            </Row>
          </Carousel.Item>
        </Carousel>
      </>
    )
  }
  return (
    <>
      <Carousel className="bg-black mb-4" indicators={false} controls={false}>
        <Carousel.Item>
          <Row className="carousel-grid">
            {hostessesArray.filter(hostesse => hostesse.id <= 2).map(hostesse => (
              <Col key={hostesse.id} className="col-carousel rounded-4 position-relative">
                <NavLink to={"/hotesses"} caseSensitive end>
                  <Image className="rounded-4 photo-hostesse" src={`/img/photos/${hostesse.photo}.webp`} fluid rounded alt="Hôtesse en lingerie"/>
                  <h6 className="neon position-absolute bottom-0 start-50 translate-middle-x">{hostesse.name}</h6>
                </NavLink>
              </Col>
            ))}
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="carousel-grid">
            {hostessesArray.filter(hostesse => hostesse.id > 2 && hostesse.id <= 4).map(hostesse => (
              <Col key={hostesse.id} className="col-carousel rounded-4 position-relative">
                <NavLink to={"/hotesses"} caseSensitive end>
                  <Image className="rounded-4 photo-hostesse" src={`/img/photos/${hostesse.photo}.webp`} fluid rounded alt="Hôtesse en lingerie"/>
                  <h6 className="neon position-absolute bottom-0 start-50 translate-middle-x">{hostesse.name}</h6>
                </NavLink>
              </Col>
            ))}
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="carousel-grid">
            {hostessesArray.filter(hostesse => hostesse.id > 4 && hostesse.id <= 6).map(hostesse => (
              <Col key={hostesse.id} className="col-carousel rounded-4 position-relative">
                <NavLink to={"/hotesses"} caseSensitive end>
                  <Image className="rounded-4 photo-hostesse" src={`/img/photos/${hostesse.photo}.webp`} fluid rounded alt="Hôtesse en lingerie"/>
                  <h6 className="neon position-absolute bottom-0 start-50 translate-middle-x">{hostesse.name}</h6>
                </NavLink>
              </Col>
            ))}
          </Row>
        </Carousel.Item>
      </Carousel>
    </>
  )
}

export default CarouselHostesses;