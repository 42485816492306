import { useParams } from "react-router-dom";
import { useState } from "react";

function useGetHostesseId(hostessesArray) {

  const { id: idParam, name: nameParam } = useParams();

  const filteredHostesse = hostessesArray.filter(hostesse => hostesse.id == idParam && hostesse.name == nameParam);

  const [hostesseProfil, setHostesseProfil] = useState(filteredHostesse.length ? filteredHostesse : false);

  if (hostesseProfil) {
    return hostesseProfil;
  }
  return false;
}

export default useGetHostesseId;