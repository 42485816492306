import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import useScreenSize from '../hooks/useScreenSize';
import { Container } from 'react-bootstrap';
import Home from '../pages/Home';
import HostessesList from '../pages/HostessesList';
import HostesseProfil from '../pages/HostesseProfil';
import PrivateDuo from '../pages/PrivateDuo';
import Audiotel from '../pages/Audiotel';
import LegalNotice from '../pages/LegalNotice';
import Cgv from '../pages/Cgv';
import ErrorPage from '../pages/404';

function Main({ sva }) {

  const { pathname } = useLocation();

  const { height } = useScreenSize();

  useEffect(() => {
    if (document.getElementById("main").offsetHeight < height) {
      document.getElementById("root").style.height = "100vh";
    }
    else {
      document.getElementById("root").style.height = "auto";
    }
  }, [pathname, height])

  return (
    <main id="main">
      <Container className='text-light'>
        <Routes>
          <Route path="/" element={<Home sva={sva} />} />
          <Route path="hotesses" element={<HostessesList />} />
          <Route path="profil-hotesse/:id/:name" element={<HostesseProfil sva={sva}/>} />
          <Route path="duo-prive" element={<PrivateDuo />} />
          <Route path="audiotel" element={<Audiotel />} />
          <Route path="mentions-legales" element={<LegalNotice />} />
          <Route path="cgv" element={<Cgv />} />
          <Route path="*" element={<Navigate to="/404" />} />
          <Route path="404" element={<ErrorPage />} />
        </Routes>
      </Container>
    </main>
  )
}

export default Main;